<template>
  <div>
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <!-- Field: Ref -->
        <b-col cols="12" md="4">
          <b-col cols="12" md="8">
            <b-form-group :label="$t('Default Language')" label-for="client">
              <v-select
                @input="onSelectChange"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="languages"
                label="name"
                input-id="languages"
                v-model="DefaultLang"
              />
            </b-form-group>
          </b-col>
          <div class="dropdown">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="default_laguage()"
              variant="primary"
            >
              {{ $t("save ") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <language-add-new
      :is-add-language-sidebar-active.sync="isAddLanguageSidebarActive"
      @refetch-data="refetchData"
      v-if="isAddLanguageSidebarActive"
    />

    <edit-emergency-degree
      :is-edit-language-sidebar-active.sync="isEditLanguageSidebarActive"
      :emergency-degree="languageRef"
      @refetch-data="refetchData"
      v-if="isEditLanguageSidebarActive"
    />
    <div style="display: flex; justify-content: flex-end; margin: 20px 0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="isAddLanguageSidebarActive = true"
        variant="primary"
      >
        {{ $t("Add language") }}
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <b-form class="p-1">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Search')" label-for="name">
              <b-form-input id="name" v-model="searchQuery" debounce="500" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-table
        ref="refEmergencyDegreeListTable"
        class="position-relative"
        :items="fetchEmergencyDegree"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <label class="switch">
            <input
              @change="UpdateStatus($event, data.item)"
              id="status_lang"
              type="checkbox"
              name="status_lang"
              :checked="data.item.status === true ? true : false"
              :disabled="data.item.code == 'en'"
            />
            <span class="slider round"></span>
          </label>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
            @click="translateWord(data.item)"
          >
            <feather-icon icon="ClipboardIcon" size="16" />
          </b-button>
          <b-button
            v-if="data.item.code != 'en'"
            @click="editlanguage(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="EditIcon" size="16" />
          </b-button>

          <b-button
            v-if="data.item.code != 'en'"
            @click="deleteLanguage(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Trash2Icon" size="16" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmergencyDegree"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import userEmergencyDegreeList from "./useWordingList";
import { ref } from "@vue/composition-api";
import LanguageAddNew from "./LanguageAddNew.vue";
import EditEmergencyDegree from "./EditWording.vue";
import Ripple from "vue-ripple-directive";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
var language_name = sessionStorage.getItem("LanguageName");
if (language_name == null) {
  language_name = "English";
}
export default {
  directives: {
    Ripple,
  },
  components: {
    LanguageAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditEmergencyDegree,
    BFormGroup,
    BForm,

    vSelect,
  },

  data() {
    return {
      name: "",
      code: "",
      DefaultLang: language_name,
    };
  },
  created() {
    this.activeLanguage();
  },
  methods: {
    async UpdateStatus(e, lang) {
      var language_name = sessionStorage.getItem("LanguageName");
      if (e.target.checked) {
        var langStatus = "on";
      } else {
        var langStatus = "off";
      }
      if (language_name == lang.name && langStatus == "off") {
        sessionStorage.setItem("LanguageName", "English");
        sessionStorage.setItem("DefaultLanguage", "en");
      }
      try {
        await instance.put(`/parameters/languages/Update-Status/${lang.id}`, {
          status: langStatus,
        });

        this.refetchData();
        this.activeLanguage();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async default_laguage() {
      var defaultLanguage = this.code;
      var name = this.name;
      sessionStorage.setItem("DefaultLanguage", defaultLanguage);
      sessionStorage.setItem("LanguageName", name);
      location.reload();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("It has been change successfully"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },
    onSelectChange: function () {
      this.code = this.DefaultLang.code;
      this.name = this.DefaultLang.name;
    },
    async activeLanguage() {
      try {
        const res = await instance.get(`/parameters/languages/active/`);
        this.languages = res.data;
        this.refetchData();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async deleteLanguage(language) {
      const agreed = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to delete it?"),
        {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (agreed) {
        try {
          await instance.delete(`/parameters/languages/Delete/${language.id}`);
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("It has been deleted successfully"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    translateWord(language) {
      this.$router.push({
        name: "translateWord",
        params: { code: language.code },
      });
    },
  },

  setup(props) {
    const isAddLanguageSidebarActive = ref(false);
    const isEditLanguageSidebarActive = ref(false);
    const languageRef = ref({});

    const {
      fetchEmergencyDegree,
      tableColumns,
      perPage,
      currentPage,
      totalEmergencyDegree,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmergencyDegreeListTable,
      refetchData,
    } = userEmergencyDegreeList();

    function editlanguage(language) {
      isEditLanguageSidebarActive.value = true;
      languageRef.value = language;
    }

    return {
      // Sidebar
      languages: [],
      isAddLanguageSidebarActive,
      isEditLanguageSidebarActive,
      fetchEmergencyDegree,
      tableColumns,
      perPage,
      currentPage,
      totalEmergencyDegree,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmergencyDegreeListTable,
      refetchData,
      editlanguage,
      languageRef,
      // Filter
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
[dir="ltr"] .dropdown[data-v-0fb6fd66] {
  margin-right: 90px;
}

.per-page-selector {
  width: 90px;
}
select.default_language {
  width: 220px;
  height: 40px;
  border-radius: 5px;
  border-color: lightgray;
  position: relative;
  z-index: 9;
}
.dropdown {
  display: flex;
  justify-content: flex-end;
  margin-top: -51px;
  margin-right: -41px;
}
option.margin {
  font-size: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #7367f0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7367f0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
